import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {getSorted} from '../../../../@theme/helpers';
import {FacilityChooserService} from '../../../../@core/facility-chooser.service';
import {DatePipe} from '@angular/common';
import {TzDatePipe} from '../../../../@theme/pipes/tz-date.pipe';
import {HoverAction} from '../../../@portal-shared/hover-components/hover-action.interface';
import {SlidePanelTypeKey, SmartLinkTypeKey} from '../../../../definitions/definitions';
import {SlidePanelOptions} from '../../../slide-panel/slide-panel';
import {Human} from '../../../../models/human';
import {Subject} from 'rxjs';
import {EntryUpdate} from '../../../../models/entry-update';
import {TableRowComponent} from './table-row/table-row.component';
import {ListPreviewComponent} from '../list-preview/list-preview.component';
import {TableBaseComponent} from '../table-base/table-base.component';

export interface SmartTableColSpec<T = any> {
    name?: string;
    template?: any;
    templateSpec?: SmartTableColTemplate;
    type?: 'colorBar' | 'user' | 'userName' | 'date' | 'dateTime' | 'tzDate' | 'tzDateTime' | 'boolean' | 'number' | 'button' | 'phoneNumber' | 'progressPercentage' | 'status';
    prop?: keyof T;
    getVal?: (item: T) => any;
    unit?: string;
    w?: number | string; // width of the column defined with bootstrap width classes
    justify?: string;
    align?: string;
    strong?: boolean;
    noLineBreak?: boolean;
    breakWord?: boolean;
    preserveNewline?: boolean;
    getColorClass?: (item: T) => string;
    getColor?: (item: T) => string;
    getBgColor?: (item: T) => string;
    spComponent?: SlidePanelTypeKey; // slide panel component
    spComponentIdFn?: (item: T) => any;
    spComponentItemFn?: (item: T) => any;
    spComponentOptionsObj?: SlidePanelOptions;
    smartLinkType?: SmartLinkTypeKey;
    getSmartLinkData?: (item: T) => {id?: number; [key: string]: any};
    routerLink?: (item?: T) => string;
    onClick?: (item?: T) => void;
    sortKey?: string; // Used in paginated table requests. The table will include this string in the request to change the sorting of the response
    shouldShow?: () => boolean;
    checkNoFacility?: boolean; // Apply *appCheckNoFacility
    onlyDetail?: boolean; // For the column to show [detail]=true should be set on the table explicitly
    onlyPrint?: boolean;
    excludeFromPrint?: boolean;
    printName?: string;
    searchField?: boolean;
    defSort?: 'asc' | 'desc';
    sortDisabled?: boolean;
    sortingFn?: (a: T, b: T) => number;
    iconBefore?: string;
    iconAfter?: string;
    permissions?: string | string[];
    meta?: any;
    headerHint?: string;
    getTooltip?: (item?: T) => string;
    getUser?: (item?: T) => any;
    getRowspan?: (item: T) => number;
}

export interface SmartTableDefinition<T = any> {
    cols: SmartTableColSpec<T>[];
    rowAction?: (item: T) => void;
    rowSpComponent?: SlidePanelTypeKey;
    hoverActions?: HoverAction[];
    tooltip?: (item: T) => string;
    tooltipDirection?: string[];
    title?: string;
    selectedIdFn?: (item: T) => any;
    rowClass?: (item: T) => string;
    getDataId?: (item: T) => number;
    updateSubj$?: Subject<EntryUpdate>;
}

export interface SmartTableColTemplate {
    index?: number;
    heading?: string;
    template: TemplateRef<any>;
    isTdTemplate?: boolean;
    headingTemplate?: TemplateRef<any>;
    meta?: any;
}

@Component({
    selector: 'app-smart-table',
    templateUrl: './smart-table.component.html',
    styleUrls: ['./smart-table.component.scss'],
})
export class SmartTableComponent<T = any> extends TableBaseComponent<T> implements OnInit, OnChanges {
    @Input() limit: number;
    @Input() tableStartTemplate: TemplateRef<any>;
    @Input() tableEndTemplate: TemplateRef<any>;
    @Input() hasLimit = true;
    @Input() enforceMoreButton = false;
    @Input() stickyHeader = false;
    @Input() shouldShowPaginationComponents = true; // Overrides the default behaviour of showing the pagination components
    @Input() smallTable = true;
    @Input() searchTerm: string;

    @Output() sortChange = new EventEmitter<any>();
    @Output() paginationChange = new EventEmitter<any>();

    @ViewChild(ListPreviewComponent) tablePreviewComponent: ListPreviewComponent;
    @ViewChild('tableElement', {read: ElementRef}) tableElement;

    dataSorted: any[];

    private _originalData: T[];

    ngOnInit() {
        this.definition.updateSubj$?.subscribe(update => {
            switch (update.type) {
                case 'delete':
                    this.data = this.data.filter(d => this.getDataId(d) !== update.id);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data?.currentValue) this._originalData = this.data;
        if (this.searchTerm) {
            const term = this.searchTerm.toLowerCase();
            const searchCols = this.definition.cols.filter(x => x.searchField);
            this.data = this._originalData.filter(x => searchCols.some(col => (`${TableRowComponent.getValue(x, col)}`).toLowerCase().includes(term)));
        } else {
            this.data = this._originalData;
        }
        if (this.data?.length) this.sortData();

        super.ngOnChanges(changes);
    }

    get tableTemplate() {
        return this.tablePreviewComponent.tableTemplate;
    }

    onSortSelect(col: SmartTableColSpec<T>) {
        if (this.isSortable(col)) {
            this.sorting = {col, desc: this.sorting.col === col ? !this.sorting.desc : false};
            this.sortData();
            this.sortChange.emit();
        }
    }

    sortData() {
        if (!this.sorting.col || typeof this.sorting.col === 'number' || typeof this.sorting.col === 'string') {
            const sortIsFromInput = typeof this.sorting.col === 'number' || typeof this.sorting.col === 'string';
            this.sorting.col = typeof this.sorting.col === 'number' ?
                this.definition.cols[this.sorting.col] :
                this.definition.cols.find(x => typeof this.sorting.col === 'string' ? (x.name === this.sorting.col || x.prop === this.sorting.col) : !!x.defSort);
            if (this.sorting.col && !sortIsFromInput) this.sorting.desc = this.sorting.col.defSort === 'desc';
        }
        if (!this.sorting.col) {
            this.dataSorted = this.data;
        } else if (this.sorting.col.sortingFn) {
            this.dataSorted = this.data.sort((a, b) => this.sorting.col.sortingFn(a, b) * (this.sorting.desc ? -1 : 1));
        } else if (this.sorting.col.type && ['user', 'userName'].includes(this.sorting.col.type)) {
            this.dataSorted = this.data.sort((a, b) => {
                const aName = Human.getName(this.getColValue(a, this.sorting.col));
                const bName = Human.getName(this.getColValue(b, this.sorting.col));
                return aName.localeCompare(bName) * (this.sorting.desc ? -1 : 1);
            });
        } else {
            this.dataSorted = getSorted(this.data, this._getSortProp(this.sorting.col), this.sorting.desc);
        }
    }

    static getDataArray<T>(data: T[], definition: SmartTableDefinition<T>, fc: FacilityChooserService, datePipe: DatePipe): (string | number)[][] {
        const colDefs = definition.cols.filter(col => !col.excludeFromPrint && col.type !== 'colorBar' && (col.getVal || col.prop || col.smartLinkType));
        const headers = colDefs.map(col => col.printName || col.name);
        const dataRows = data.map(item => colDefs.map(col => {
            let val = this.getColValue(item, col) ?? '';
            if (col.type === 'tzDate') val = TzDatePipe.transform(fc, datePipe, val);
            else if (['user', 'userName'].includes(col.type)) val = Human.getName(val);
            // To include the unit if there's a value to the column, and even if the value's 0
            return (col.unit && val || val === 0) ? `${val} ${col.unit}` : val;
        }));

        return [headers, ...dataRows];
    }

    static getColValue<T>(item: T, col: SmartTableColSpec<T>) {
        return TableRowComponent.getValue(item, col);
    }

    getColValue(item: T, col: SmartTableColSpec<T>) {
        return SmartTableComponent.getColValue(item, col);
    }

    getTooltip(item) {
        if (!this.definition.tooltip) return;

        return this.definition.tooltip(item);
    }

    isSortable(col: SmartTableColSpec<T>) {
        return this.hasSorting && col.type != 'colorBar' && !col.sortDisabled;
    }

    private _getSortProp(col: SmartTableColSpec<T>) {
        if (['date', 'dateTime', 'tzDate', 'tzDateTime'].includes(col.type)) {
            return x => {
                const d = this.getColValue(x, col);
                return d ? new Date(d).getTime() : 0;
            };
        }
        return col.getVal || col.prop;
    }
}
