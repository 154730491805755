<td class="w-0 text-center" *ngIf="selectable"><input type="checkbox" [ngModel]="item.selected" (ngModelChange)="onSelectRow($event, item)" (click)="$event.stopPropagation()"></td>
<ng-container *ngFor="let col of definition.cols; let i = index">
    <ng-container *ngFor="let t of getInsertTemplateCols(i)">
        <ng-container *ngTemplateOutlet="t.template; context: {item: item, last: last, meta: t.meta}"></ng-container>
    </ng-container>
    <ng-container *ngxPermissionsOnly="col.permissions">
        <ng-container *ngIf="!col.onlyDetail || col.onlyDetail && shouldShowDetails" [ngSwitch]="col.type">
            <ng-container *ngIf="col.shouldShow; else tdt">
                <ng-container *ngIf="col.shouldShow()" [ngTemplateOutlet]="tdt"></ng-container>
            </ng-container>
            <ng-template #tdt>
                <ng-container *ngIf="col.checkNoFacility; else tdtt">
                    <ng-container *appCheckNoFacility [ngTemplateOutlet]="tdtt"></ng-container>
                </ng-container>
            </ng-template>
            <ng-template #tdtt>
                <ng-container *ngIf="getTemplate(i) as t; else tdttt">
                    <ng-container *ngIf="t.isTdTemplate; else tdttt">
                        <ng-container *ngTemplateOutlet="t.template; context: {item: item, meta: t.meta}"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-template>
            <ng-template #tdttt>
                <ng-container *ngIf="col.type === 'colorBar'; else tdTemplate">
                    <td class="color-bar-cell"
                        [attr.rowspan]="col.getRowspan?.(item)">
                        <div class="color-bar bg-{{getValue(item, col)}}"
                             [style.backgroundColor]="col.getColor ? col.getColor(item) : null">
                        </div>
                    </td>
                </ng-container>
            </ng-template>
        </ng-container>
    </ng-container>

    <ng-template #tdTemplate>
        <td class="{{col.justify ? ' text-' + col.justify : ''}}{{col.align ? ' align-' + col.align : ''}}{{col.getColorClass ? ' text-' + col.getColorClass(item) : ''}}{{col.onlyPrint ? ' d-none d-print-table-cell' : ''}}"
            [attr.aria-label]="!col.name && 'empty'"
            [attr.rowspan]="col.getRowspan?.(item)"
            [style.color]="col.type !== 'status' && col.getColor ? col.getColor(item) : null"
            [style.background-color]="col.getBgColor ? col.getBgColor(item) : null"
            [style.width]="col.w || col.w === 0 ? col.w + '%' : null"
            [style.word-break]="col.breakWord ? 'break-word': null"
            [class.strong]="col.strong"
            [class.no-line-break]="col.noLineBreak || col.type == 'tzDate' || col.unit"
            [class.color-bar-cell]="col.type == 'colorBar'">
            <ng-container *ngIf="getTemplate(i) as t; else valueTmp">
                <ng-container *ngTemplateOutlet="t.template; context: {item: item, meta: t.meta}"></ng-container>
            </ng-container>
            <ng-template #valueTmp>
                <ng-container *ngTemplateOutlet="valueTmp; context: {value: getValue(item, col)}"></ng-container>
                <ng-template #valueTmp let-value="value">
                    <ng-container *ngIf="value || value === 0 || value === false">
                        <ng-container *ngIf="col.smartLinkType; else valueTmp">
                            <app-smart-link [type]="col.smartLinkType"
                                            [data]="getSmartLinkData(item, col)"
                                            [pageThrough]="getPageThrough(col)"
                                            [pagingSecondaryId]="item.id"
                                            (click)="$event.stopPropagation()">
                            </app-smart-link>
                        </ng-container>
                        <ng-template #valueTmp>
                            <ng-container *ngIf="col.spComponent; else valueTmp">
                                <span *ngIf="col.spComponent === 'PATIENT'; else defSpTmp"
                                      hoverPatientActions
                                      [patient]="getPatient(item, col)">
                                    <a tabindex="-1"
                                       (click)="onOpenSlidePanel($event, col, item)">
                                        <ng-container *ngTemplateOutlet="valueTmp"></ng-container>
                                    </a>
                                </span>
                                <ng-template #defSpTmp>
                                    <a tabindex="-1"
                                       class="smart-link"
                                       (click)="onOpenSlidePanel($event, col, item)">
                                        <ng-container *ngTemplateOutlet="valueTmp"></ng-container>
                                    </a>
                                </ng-template>
                            </ng-container>
                            <ng-template #valueTmp>
                                <ng-container [ngSwitch]="col.type">
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchCase="'user'"><app-user-icon [user]="value" size="sm"></app-user-icon></span>
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchCase="'userName'" class="d-inline-flex align-items-center">
                                        <app-user-icon [user]="value" size="sm" [hasTooltip]="false"></app-user-icon>
                                        <span class="ml-2">{{value | humanName}}</span>
                                    </span>
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchCase="'date'">{{value | simpleDate:false}}</span>
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchCase="'tzDate'">{{value | simpleDate}}</span>
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchCase="'dateTime'">{{value | simpleDate:false:true}}</span>
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchCase="'tzDateTime'">{{value | simpleDate:true:true}}</span>
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchCase="'number'">{{value | number:'1.0-1'}}<small *ngIf="shouldShowUnit(col, item)"> {{col.unit}}</small></span>
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchCase="'phoneNumber'">{{value | phoneFormat}}</span>
                                    <app-progress-bar *ngSwitchCase="'progressPercentage'"
                                                      [class.mx-auto]="col.justify === 'center'"
                                                      [class.ml-auto]="col.justify === 'right'"
                                                      [progress]="value"
                                                      [color]="col.getColor?.(item)"
                                                      [colorClass]="col.getColorClass?.(item)">
                                    </app-progress-bar>
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchCase="'boolean'">
                                        <far *ngIf="value" i="check" class="text-success"></far>
                                        <far *ngIf="value === false" i="times" class="text-danger"></far>
                                    </span>
                                    <app-status-label *ngSwitchCase="'status'"
                                                      [color]="col.getColor?.(item)"
                                                      [colorClass]="col.getColorClass?.(item)"
                                                      [label]="value"
                                                      [strong]="col.strong ?? true"
                                                      [user]="col.getUser?.(item)"
                                                      (click)="col.onClick?.(item)"
                                                      [class.cursor-pointer]="col.onClick"
                                                      [tooltip]="col.getTooltip?.(item)">
                                    </app-status-label>
                                    <span [class.preserve-newline]="col.preserveNewline" *ngSwitchDefault>{{value}}<small *ngIf="shouldShowUnit(col, item)"> {{col.unit}}</small></span>
                                    <ng-container *ngSwitchCase="'button'">
                                        <ng-container *ngIf="value">
                                            <button *ngIf="col.routerLink" class="btn btn-sm btn-primary" [routerLink]="col.routerLink(item)">
                                                <fal *ngIf="col.iconBefore" [i]="col.iconBefore"></fal>
                                                {{value}}
                                                <fal *ngIf="col.iconAfter" [i]="col.iconAfter"></fal>
                                            </button>
                                            <button *ngIf="col.onClick" class="btn btn-sm btn-primary" (click)="col.onClick(item)">
                                                <fal *ngIf="col.iconBefore" [i]="col.iconBefore"></fal>
                                                {{value}}
                                                <fal *ngIf="col.iconAfter" [i]="col.iconAfter"></fal>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-template>
                        </ng-template>
                    </ng-container>
                </ng-template>
            </ng-template>
        </td>
    </ng-template>
</ng-container>
<td *ngIf="reviewType">
    <app-review-indication [entry]="item" [type]="reviewType"></app-review-indication>
</td>
<ng-container *ngFor="let t of getInsertTemplateCols()">
    <ng-container *ngTemplateOutlet="t.template; context: {item: item, last: last, meta: t.meta}"></ng-container>
</ng-container>
